import React, { useState } from 'react';
import { BrowserRouter as Router, Route, useLocation, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history'; 
import $ from 'jquery';
import './App.css';
import Firstsidebar from './components/Firstsidebar';
import Secondsidebar from './components/Secondsidebar';
import Screen3 from './screen3';
// import Screen4 from './screen4';
// import Screen5 from './screen5';
// import Screen1 from './Screen1';
import Screen1v2 from './Screen1v2';
import Screen2 from './screen2';
import Single from './Single';
import { MyProvider } from './context';
import { useAppContext } from './appContext';
import UploadTemporalInformation from './components/UploadTemporalInformation';
import UploadProducerAccounts from './components/uploadProducerAccounts';

const MainContent = ({ setId, visitedPaths, setVisitedPaths, appContext, id }) => {
  const location = useLocation();

  if (location?.pathname === '/upload-temporal-information') {
    return (
        <UploadTemporalInformation />
    )
  } else if (location?.pathname === '/upload-producer-accounts') {
    return (
        <UploadProducerAccounts />
    )
  } else {
    return (
      <>
        <div className='allComponentWrapper'>
          <Route render={(props) => (
            <Firstsidebar {...props} appContext={appContext} setVisitedPaths={setVisitedPaths} />
          )} />
          <div className="itmain">
            <MyProvider>
              <Screen1v2 abc={$(window).width() >= 320 && $(window).width() <= 900 ? id : null} setVisitedPaths={setVisitedPaths} visitedPaths={visitedPaths} isScreenVisible={$(window).width() > 0} />
            </MyProvider>
            <MyProvider>
              <Screen2 appContext={appContext} abc={$(window).width() >= 1701 && $(window).width() <= 2150 ? id : null} resetAbc={() => setId('')} isScreenVisible={!($(window).width() < 1701)} />
            </MyProvider>
            <MyProvider>
              <Screen3 appContext={appContext} abc={$(window).width() >= 2151 ? id : null} resetAbc={() => setId('')} isScreenVisible={!($(window).width() < 2151)} />
            </MyProvider>
            {/* <MyProvider>
              <Screen4 appContext={appContext} abc={$(window).width() >= 3326 && $(window).width() <= 4500 ? id : null} resetAbc={() => setId('')} isScreenVisible={!($(window).width() < 3326)} />
          </MyProvider>
          <MyProvider>
              <Screen5 appContext={appContext} abc={$(window).width() >= 4501 ? id : null} resetAbc={() => setId('')} isScreenVisible={!($(window).width() < 4501)} />
          </MyProvider> */}
          </div>
          <Secondsidebar appContext={appContext} click_detect={(id) => setId(id)} />
        </div>
      </>
    );
  }
};

const App = () => {
  const [id, setId] = useState('');
  const [visitedPaths, setVisitedPaths] = useState([]);
  const appContext = useAppContext();

  return (
    <Router history={createBrowserHistory}>
      {localStorage.getItem('root') && localStorage.getItem('root') !== null ? (
        <MainContent
          setId={setId}
          visitedPaths={visitedPaths}
          setVisitedPaths={setVisitedPaths}
          appContext={appContext}
          id={id}
        />
      ) : (
        <Route exact path="/" component={Single} />
      )}
    </Router>
  );
};

export default App;
